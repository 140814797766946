/**形状工具*/
import  SHAPECONFIG from '../config/shapeConfig.js'
import { fabric } from 'fabric-with-erasing';

  /**
   * 绘制正方形
   * @param {*} left 距离左侧距离
   * @param {*} top  距离上边距离
   * @param {*} width 矩形宽度
   * @param {*} height 矩形高度
   * @param {*} fabricObj  画布
   */
 function drawRect(left,top,width,height,fabricObj){
    var lineA =  new fabric.Line([left,top,width+left,top],SHAPECONFIG.lineStyle);
    var lineB =  new fabric.Line([left,top,left,height+top],SHAPECONFIG.lineStyle);
    var lineC =  new fabric.Line([left,height+top,width+left,height+top],SHAPECONFIG.lineStyle);
    var lineD =  new fabric.Line([width+left,top,width+left,height+top],SHAPECONFIG.lineStyle);

    // lineA.animate("left",width+left, {
    //   onChange: fabricObj.renderAll.bind(fabricObj)
    // })

    fabricObj.add(lineA,lineB,lineC,lineD);

    return [lineA,lineB,lineC,lineD];
  }

  /**
   * 绘制矩形--正面除了上边框有弱线，其他线有样式
   * @param {*} left 距离左侧距离
   * @param {*} top  距离上边距离
   * @param {*} width 矩形宽度
   * @param {*} height 矩形高度
   * @param {*} fabricObj  画布
   */
  function drawRectV2(left,top,width,height,fabricObj){
    var lineA =  new fabric.Line([left,top,width+left,top],SHAPECONFIG.lineStyle);
    var lineB =  new fabric.Line([left,top,left,height+top],SHAPECONFIG.lineStyleV2);
    var lineC =  new fabric.Line([left,height+top,width+left,height+top],SHAPECONFIG.lineStyleV2);
    var lineD =  new fabric.Line([width+left,top,width+left,height+top+4],SHAPECONFIG.lineStyleV2);

    fabricObj.add(lineA,lineB,lineC,lineD);
    return [lineA,lineB,lineC,lineD];
  }

//绘制立方体 正视图
 function drawCube(left,top,width,height,deep,fabricObj){

    var miniWidth=Math.ceil((width*(100-deep))/100);
    var miniHeight=Math.ceil((height*(100-deep))/100);
    var miniLeft=Math.ceil(((width-miniWidth)/2)+left);
    var miniTop=Math.ceil(((height-miniHeight)/2)+top);
    var lineArrA = drawRect(left,top,width,height,fabricObj);
    var lineArrB = drawRect(miniLeft,miniTop,miniWidth,miniHeight,fabricObj);

    //绘制两个正方形的映射连线
    var lineA =  new fabric.Line([left,top,miniLeft,miniTop],SHAPECONFIG.lineStyle);
    var lineB =  new fabric.Line([left+width,top,miniLeft+miniWidth,miniTop],SHAPECONFIG.lineStyle);
    var lineC =  new fabric.Line([left,top+height,miniLeft,miniTop+miniHeight],SHAPECONFIG.lineStyle);
    var lineD =  new fabric.Line([left+width,top+height,miniLeft+miniWidth,miniTop+miniHeight],SHAPECONFIG.lineStyle);
    var lineArrC=[lineA,lineB,lineC,lineD]

    fabricObj.add(lineA,lineB,lineC,lineD);
    //规制几个面颜色
    // _.drawPolygon([{x:left,y:top},{x:miniLeft,y:miniTop},{x:miniLeft,y:miniTop+miniHeight},{x:left,y:top+height}],"red");
    return lineArrA.concat(lineArrB).concat(lineArrC);
  }

  //这是带有一点俯视图的立体图形
  function drawCubeV2(left,top,width,height,deep,fabricObj){
    // deep=Math.ceil((deep/width)*100);
    // var miniWidth=Math.ceil(((width*((100-deep)*4)/300)));
    // console.log(miniWidth)
    // var miniHeight=Math.ceil(((height*(100-deep))/100));
    // var miniLeft=Math.ceil(((width-miniWidth)/2)+left);
    // // var miniTop=Math.ceil(((height-miniHeight)/2)+top-60);
    // var miniTop=Math.ceil(((height-miniHeight)/2)+((top*2)/5));
    // var lineArrA= drawRectV2(left,top,width,height,fabricObj);
    // var lineArrB= drawRect(miniLeft,miniTop,miniWidth,miniHeight,fabricObj);

    // //绘制两个正方形的映射连线
    // var lineA =  new fabric.Line([left,top,miniLeft,miniTop],SHAPECONFIG.lineStyle);
    // var lineB =  new fabric.Line([left+width,top,miniLeft+miniWidth,miniTop],SHAPECONFIG.lineStyle);
    // var lineC =  new fabric.Line([left,top+height,miniLeft,miniTop+miniHeight],SHAPECONFIG.lineStyle);
    // var lineD =  new fabric.Line([left+width,top+height,miniLeft+miniWidth,miniTop+miniHeight],SHAPECONFIG.lineStyle);
    // var lineArrC=[lineA,lineB,lineC,lineD];

    // fabricObj.add(lineA,lineB,lineC,lineD);

    // return lineArrA.concat(lineArrB).concat(lineArrC);


    //测试
    // deep=Math.ceil((deep/width)*100);
    var addLeft=Math.ceil((width*3)/46);
    var addTop=Math.ceil((deep*7)/23);
    var miniWidth=Math.ceil((width-(addLeft*2)));
    var miniHeight=Math.ceil((height*9)/14);
    var miniLeft=Math.ceil(addLeft+left);
    var miniTop=Math.ceil(top-addTop);
    var lineArrA= drawRectV2(left,top,width,height,fabricObj);
    var lineArrB= drawRect(miniLeft,miniTop,miniWidth,miniHeight,fabricObj);

    //绘制两个正方形的映射连线
    var lineA =  new fabric.Line([left,top,miniLeft,miniTop],SHAPECONFIG.lineStyle);
    var lineB =  new fabric.Line([left+width,top,miniLeft+miniWidth,miniTop],SHAPECONFIG.lineStyle);
    var lineC =  new fabric.Line([left,top+height,miniLeft,miniTop+miniHeight],SHAPECONFIG.lineStyle);
    var lineD =  new fabric.Line([left+width,top+height,miniLeft+miniWidth,miniTop+miniHeight],SHAPECONFIG.lineStyle);
    var lineArrC=[lineA,lineB,lineC,lineD];

    fabricObj.add(lineA,lineB,lineC,lineD);

    return lineArrA.concat(lineArrB).concat(lineArrC);


    //规制几个面颜色
    // _.drawPolygon([{x:left,y:top},{x:miniLeft,y:miniTop},{x:miniLeft,y:miniTop+miniHeight},{x:left,y:top+height}],"red");
  }

//绘制不规则图形
function drawPolygon(points,fillColor,fabricObj){
    var polygon = new fabric.Polygon(points,{
        fill: fillColor,
        selectable: false,
        objectCaching: false,
    });
    fabricObj.add(polygon);
}



//滚轮滚动事件 wheel--根据鼠标为中心位置缩放
function mouseWheelToBig(fabricObj){
  fabricObj.on('mouse:wheel', function(opt) {
    var delta = opt.e.deltaY;
    var zoom = fabricObj.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    fabricObj.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  })
}

//绘制图片+文字  elInfo 存储扩展信息 tagEl 元素归类  calcObj 是否是参与统计的元素  position 位置
function drawSmallImgAndText(item,fabricObj,x,y){
  const image = new Image()
  image.setAttribute('crossorigin', 'anonymous');
  image.src =item.img+'?tamp='+(new Date()).valueOf();
  image.onload = function(){
    //图片加载完，再draw 和 toDataURL
    // const imgElement = document.getElementById(item.code);
   var imgStyle= JSON.parse(JSON.stringify(SHAPECONFIG.imgStyle));
    // imgStyle.scaleX=0.02;
    // imgStyle.scaleY=0.02;
    imgStyle.selectable=false;
    const imgInstance = new fabric.Image(image,imgStyle);
    imgInstance.top= y;
    imgInstance.left= x;
    imgInstance.scaleX=40/imgInstance.width;
    imgInstance.scaleY=40/imgInstance.height;
    item['preWidth']=imgInstance.width;
    item['preHeight']=imgInstance.height;
    item['scaleY']=imgInstance.scaleY;
    item['scaleX']=imgInstance.scaleX;
    imgInstance["elInfo"] = item;
    imgInstance["tagEl"] = 1;
    imgInstance["calcObj"] = 1;
    imgInstance["position"] = 't';
    fabricObj.add(imgInstance); 

    fabricObj.add(new fabric.Text(item.name, { 
        left: x, //Take the block's position
        top:y+50, 
        fill: 'black',
        fontSize:13,
        position:'t',
        elInfo:item
    }));
  };
}


//绘制图片+文字
function drawSmallImgAndTextAndPrice(item,fabricObj,x,y){
  // const image = new Image()
  // image.setAttribute('crossorigin', 'anonymous');
  // image.src =item.img+'?tamp='+(new Date()).valueOf();
  // image.onload = function(){
    //图片加载完，再draw 和 toDataURL
  //  var imgStyle= JSON.parse(JSON.stringify(SHAPECONFIG.imgStyle));
  //   imgStyle.selectable=false;
  //   const imgInstance = new fabric.Image(image,imgStyle);
  //   imgInstance.top= y;
  //   imgInstance.left= x;
  //   imgInstance.scaleX=40/imgInstance.width;
  //   imgInstance.scaleY=40/imgInstance.height;
  //   imgInstance["elInfo"] = item;
  //   imgInstance["tagEl"] = 1;
  //   imgInstance["position"] = 'r';
  //   imgInstance["calcObj"] = 1;
  //   fabricObj.add(imgInstance); 

    // console.log("图片高度",imgInstance.height)
    fabricObj.add(new fabric.Text(item.name+'('+item.priceDesc+')', { 
        left: x, //Take the block's position
        top:y, 
        fill: 'black',
        fontSize:12,
        elInfo:item,
        tagEl:0,
        position:'r',
        calcObj: 2,
        selectable:false
    }));

    fabricObj.add(new fabric.IText("x "+(item['num']??1), { 
        left: x+220, //Take the block's position
        top:y, 
        fill: 'black',
        fontSize:14,
        elInfo:item,
        tagEl:0,
        position:'r',
        padding:7,
    }));
  // };
}

//绘画直线
//绘制图片+文字
function drawPriceLine(fabricObj,x,y,totalPrice){
   var lineStyle= JSON.parse(JSON.stringify(SHAPECONFIG.lineStyle));
   lineStyle.selectable=false;
    const lineInstance = new fabric.Line([x,y,240+x,y],lineStyle);
    lineInstance["tagEl"] = 2;
    lineInstance["position"] = 'r';
    fabricObj.add(lineInstance); 

    fabricObj.add(new fabric.Text("配套总价", { 
      left: x, 
      top:y+10, 
      fill: 'black',
      fontSize:14,
      tagEl:2,
      position:'r',
      selectable:false
  }));

    fabricObj.add(new fabric.Text("¥"+totalPrice, { 
        left: x+200, 
        top:y+10, 
        fill: 'black',
        fontSize:14,
        tagEl:2,
        position:'r',
        selectable:false
    }));
}


//绘画虚线
function drawDashLine(fabricObj,type){
  var sx=0;
  var sy=0;
  var ex=0;
  var ey=0;
  if(type=='H'){
    sx=0;
    ex=890;
    sy=156;
    ey=156;
  }
  if(type=='Z'){
    sx=890;
    ex=890;
    sy=0;
    ey=740;
  }
   const lineInstance = new fabric.Line([sx,sy,ex,ey],{
    strokeDashArray: [5, 5], 
    stroke: 'black',
    selectable:false
   });
   fabricObj.add(lineInstance); 
}

const shape={
    drawRect,
    drawCube,
    drawCubeV2,
    drawPolygon,
    mouseWheelToBig,
    drawSmallImgAndText,
    drawSmallImgAndTextAndPrice,
    drawPriceLine,
    drawDashLine
}

export default shape;