<template>
  <DesignContainer/>
</template>

<script>
// import MainWindow from './view/window/main/index.vue'
import DesignContainer from './view/window/designContainer/index.vue'
// import DesignContainer from './view/window/designContainer/test.vue'

export default {
  name: 'App',
  components: {
    DesignContainer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
