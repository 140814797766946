//物品
const goods=[
    {name:"阿根廷皇冠",code:"AGTHG"},
    {name:"翠柳",code:"CL"},
    {name:"大巴榕",code:"DBR"},
    {name:"大丹麦",code:"DDM"},
    {name:"凤尾苔（半球）",code:"FWTBQ"},
    {name:"红椒",code:"HJ"},
    {name:"红菊",code:"HJU"},
    {name:"红牛顿",code:"HND"},
    {name:"红丝青叶",code:"HSQY"},
    {name:"红尾松",code:"HWS"},
    {name:"红雨伞",code:"HYS"},
    {name:"虎耳",code:"HER"},
    {name:"槐叶萍",code:"HYP"},
    {name:"黄金榕",code:"HJR"},
    {name:"火焰丁香",code:"HYDX"},
    {name:"椒榕",code:"JR"},
    {name:"金钱榕",code:"JQR"},
    {name:"金鱼藻",code:"JYZ"},
    {name:"咖啡椒",code:"KFJ"},
    {name:"辣椒榕",code:"LJR"},
    {name:"六角铁",code:"LJT"},
    {name:"绿波",code:"LVB"},
    {name:"绿宫廷",code:"LGT"},
    {name:"绿菊",code:"JR"},
    {name:"绿尾松",code:"LWS"},
    {name:"绿温力椒",code:"LWLJ"},
    {name:"玫瑰丁香",code:"MGDX"},
    {name:"迷你矮珍珠",code:"MNAZZ"},
    {name:"迷你丹麦",code:"MNDM"},
    {name:"迷你九罐",code:"MNJG"},
    {name:"迷你青椒",code:"MNQJ"},
    {name:"迷你小榕",code:"MNXR"},
    {name:"迷你小水兰（果冻杯）",code:"MNXSLGDB"},
    {name:"莫斯",code:"MS"},
    {name:"牡丹榕",code:"MDR"},
    {name:"南美针叶",code:"NMZY"},
    {name:"南美针叶皇冠",code:"NMZYHG"},
    {name:"牛毛",code:"NM"},
    {name:"牛毛（大）",code:"NMD"},
    {name:"趴地矮珍珠",code:"PDAZZ"},
    {name:"苹果草",code:"PGC"},
    {name:"青弹头",code:"QTT"},
    {name:"青竹",code:"QZ"},
    {name:"日本矮珍珠",code:"RBAZZ"},
    {name:"日本小榕",code:"RBXR"},
    {name:"三剑榕",code:"SJR"},
    {name:"水芙蓉",code:"SFR"},
    {name:"水罗兰",code:"SLL"},
    {name:"泰国尖叶榕",code:"TGJYR"},
    {name:"泰国青椒",code:"TGQJ"},
    {name:"泰国水剑",code:"TGSJ"},
    {name:"天府葵（杯装）",code:"TFKBZ"},
    {name:"铁皇冠",code:"THG"},
    {name:"瓦利斯榕",code:"WLSR"},
    {name:"蜈蚣草",code:"WGC"},
    {name:"细叶铁",code:"XYT"},
    {name:"细叶铁（半球）",code:"XYTBQ"},
    {name:"小巴榕",code:"XBR"},
    {name:"小红玫",code:"XHM"},
    {name:"小圆叶",code:"XYY"},
    {name:"血红丁香",code:"XHDX"},
    {name:"血红宫廷",code:"XHGT"},
    {name:"血心兰",code:"XXL"},
    {name:"燕尾榕",code:"YWR"},
    {name:"雨裂",code:"YL"},
    {name:"圆心亭",code:"YXT"},
    {name:"紫丁香",code:"ZDX"}
]

const config={
    goods
}

export default config;