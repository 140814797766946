<template>
  <div
    class="designContainer"
    @contextmenu.prevent="mouseKeyClick($event, 'r')"
    @click.left="mouseKeyClick($event, 'l')"
  >
    <!--容器元素选择-->
    <div class="designContainer_left_setting">
      <div class="designContainer_left_setting_title">元素列表</div>
      <div class="designContainer_left_setting_search">
        <input type="text" v-model="searchThingName" placeholder="搜索物品" />
      </div>

      <div class="designContainer_left_setting_list">
        <div
          class="designContainer_left_setting_list_content"
          v-for="(item, index) in goods"
          :key="index"
        >
          <div
            class="designContainer_left_setting_list_content_class"
            @click="showClassGoods('parentClass', index)"
          >
            {{ item.bigTypeName }}
          </div>
          <div
            v-show="parentOpenIndex == index"
            v-for="(subItem, subIndex) in item.supProducts"
            :key="subIndex"
          >
            <div
              @click="showClassGoods('subClass', subIndex)"
              class="designContainer_left_setting_list_content_subclass"
            >
              {{ subItem.typeName }}
            </div>
            <div
              class="designContainer_left_setting_list_content_product"
              v-show="currentOpenIndex == subIndex"
            >
              <div
                class="designContainer_left_setting_list_content_product_item"
                v-for="(smallItem, smallIndex) in subItem.products"
                :key="smallIndex"
              >
                <img
                  draggable="true"
                  :id="smallItem.orderNo"
                  @mousedown="dragImgDown($event)"
                  @mouseout="dragImgOut($event, smallItem)"
                  :src="smallItem.img"
                  alt=""
                />
                <div>{{ smallItem.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--右侧容器展示-->
    <div class="designContainer_right_show">
      <canvas id="canvas"></canvas>
      <div class="designContainer_right_show_operation">
        <img
          class="designContainer_right_show_operation_erase"
          @click="eraseHandler('eraseactive')"
          v-if="erase != 'eraseactive'"
          src="../../../assets/erase.png"
          alt=""
        />
        <img
          class="designContainer_right_show_operation_erase"
          @click="eraseHandler('erasenormal')"
          v-if="erase == 'eraseactive'"
          src="../../../assets/erase_active.png"
          alt=""
        />

        <!--恢复-->
        <img
          class="designContainer_right_show_operation_erase"
          @click="eraseHandler('recoveractive')"
          v-if="erase != 'recoveractive'"
          src="../../../assets/recover.png"
          alt=""
        />
        <img
          class="designContainer_right_show_operation_erase"
          @click="eraseHandler('recovernormal')"
          v-if="erase == 'recoveractive'"
          src="../../../assets/recover_active.png"
          alt=""
        />

        <img
          class="designContainer_right_show_operation_erase"
          @click="backgroundImgFixHandler(true)"
          v-if="!backImgFix"
          src="../../../assets/fixation.png"
          alt=""
        />
        <img
          class="designContainer_right_show_operation_erase"
          @click="backgroundImgFixHandler(false)"
          v-if="backImgFix"
          src="../../../assets/fixation_active.png"
          alt=""
        />
      </div>
      <!--元素图层拖动调节层级-->
      <div class="designContainer_right_show_level">
        <img
          draggable="true"
          @mousedown="dragLevelDown($event,index)"
          @mouseout="dragLevelOut($event, item)"
          @dragenter="dragLevelEnter($event,item)"
          v-for="(item, index) in picLevel"
          :src="item.img"
          :key="index"
          alt=""
        />
      </div>
      <!--参数设置-->
      <div class="designContainer_right_show_define">
        <div class="designContainer_right_show_define_item">
          <span>透明度</span>
          <input
            v-model="userDefined.opacity"
            placeholder="透明度"
            type="text"
          />
        </div>
        <div class="designContainer_right_show_define_item">
          <span>宽度</span>
          <input v-model="userDefined.width" placeholder="宽度" type="text" />
        </div>
        <div class="designContainer_right_show_define_item">
          <span>高度</span>
          <input v-model="userDefined.height" placeholder="高度" type="text" />
        </div>
        <div class="designContainer_right_show_define_item">
          <span>深度</span>
          <input v-model="userDefined.deep" placeholder="深度" type="text" />
        </div>
        <div class="designContainer_right_show_define_item">
          <span>客户名称</span>
          <input
            v-model="userDefined.customerName"
            placeholder="客户名称"
            type="text"
          />
        </div>
        <div class="designContainer_right_show_define_item">
          <span>备注</span>
          <input v-model="userDefined.remark" placeholder="备注" type="text" />
        </div>
      </div>
      <div class="designContainer_right_show_btns">
        <div
          class="designContainer_right_show_btns_import"
          @click="importCanvasToImg"
        >
          导入图片
        </div>
        <input
          type="file"
          @change="importFileHandler"
          style="display: none"
          ref="uploadFile"
        />
        <div
          class="designContainer_right_show_btns_next"
          @click="exportCanvasToImg"
        >
          导出图片
        </div>
      </div>
    </div>

    <!--右键菜单-->
    <div
      class="designContainer_right_menu"
      v-if="rightMenuInfo.visible"
      :style="{
        left: rightMenuInfo.left + 'px',
        top: rightMenuInfo.top + 'px',
        zIndex: 100,
      }"
    >
      <div
        class="designContainer_right_menu_item"
        @click="rightMenuHandler('up')"
      >
        上一层
      </div>
      <div
        class="designContainer_right_menu_item"
        @click="rightMenuHandler('down')"
      >
        下一层
      </div>
      <div
        class="designContainer_right_menu_item"
        @click="rightMenuHandler('bottom')"
      >
        置底
      </div>
      <div
        class="designContainer_right_menu_item"
        @click="rightMenuHandler('delete')"
      >
        删除
      </div>
    </div>
  </div>
</template>
   
  <script>
import { fabric } from "fabric-with-erasing";
import GOODSCONFIG from "../../../config/goodsConfig.js";
import SnowFlakeId from "snowflake-id";
import axios from "axios";
var _ = this;

//elType 0 代表纯色背景图
export default {
  name: "DesignContainer",
  data() {
    return {
      currentOpenIndex: 0,
      parentOpenIndex: 0,
      //背景固定
      backImgFix: false,
      backImgComponent: null,
      //橡皮擦
      erase: "normal",
      searchThingName: null,
      //物品列表
      goods: null,
      //原始物品列表
      orignGoods: null,
      //选择的物品列表
      selectionEls: [],
      selectRightEls: [],
      //画布的宽高
      canvasInfo: {
        width: 1160,
        height: 740,
      },
      //用户自定义
      userDefined: {
        width: null,
        height: null,
        deep: null,
        top: null,
        left: null,
        customerName: null,
        remark: null,
        opacity: null,
      },
      //选项
      options: [
        {
          title: "长方体容器",
          width: 600,
          height: 300,
          deep: 300,
          imgUrl: "",
        },
      ],
      //是否拖动
      isDrag: false,
      //鼠标位置
      mouseMoveXY: {
        x: 0,
        y: 0,
      },
      //右键菜单显示与否
      rightMenuInfo: {
        visible: false,
        top: 0,
        left: 0,
      },
      //MAX
      maxzIndex: 1,
      prezIndex: 1,
      //雪花id
      flakeId: null,
      //图层
      picLevel: [],
      //当前图层
      currentLevel:null,
      enterLevelItem:null
    };
  },
  watch: {
    //自定义宽度
    "userDefined.width": function (newVal, oldVal) {
      _.userDefined.width = newVal ? parseInt(newVal) : 0;
      _.showContainerSizeInfo();
    },
    //自定义高度
    "userDefined.height": function (newVal, oldVal) {
      _.userDefined.height = newVal ? parseInt(newVal) : 0;
      _.showContainerSizeInfo();
    },
    //自定义深度
    "userDefined.deep": function (newVal, oldVal) {
      _.userDefined.deep = newVal ? parseInt(newVal) : 0;
      _.showContainerSizeInfo();
    },
    //客户名称
    "userDefined.customerName": function (newVal, oldVal) {
      _.userDefined.customerName = newVal;
      _.showContainerSizeInfo();
    },
    //客户名称
    "userDefined.remark": function (newVal, oldVal) {
      _.userDefined.remark = newVal;
      _.showContainerSizeInfo();
    },
    "userDefined.opacity": function (newVal, oldVal) {
      _.userDefined.opacity = newVal;
      _.changeSelectElsOpacity();
    },
    //搜索物品
    searchThingName: function (newVal, oldVal) {
      _.queryClazzProduct(newVal);
    },
  },
  components: {},
  mounted() {
    _.fabricObj = new fabric.Canvas("canvas");
    _.fabricObj.setWidth(_.canvasInfo.width);
    _.fabricObj.setHeight(_.canvasInfo.height);
    _.drawColorBackground();
    _.$SHAPE.drawDashLine(_.fabricObj, "H");
    _.$SHAPE.drawDashLine(_.fabricObj, "Z");
    //滚动
    // _.$SHAPE.mouseWheelToBig(_.fabricObj);

    //画骨架
    // _.options[0].left=(_.canvasInfo.width-_.options[0].width)/2
    // _.options[0].top=(_.canvasInfo.height-_.options[0].height)/2
    // _.drawShape(_.options[0])

    //绘制背景纯色
    _.fabricObj.setBackgroundColor("#4a7983");

    //鼠标位置
    _.fabricObj.on("mouse:move", function (opt) {
      _.mouseMoveXY.x = opt.e.offsetX;
      _.mouseMoveXY.y = opt.e.offsetY;
    });

    //对象被修改事件
    _.fabricObj.on("object:modified", function (opt) {
      console.log("修改", opt);
      _.numChangeCalcHandler(opt);
    });

    //按键监听
    document.onkeydown = function (e) {
      _.monitorEventKey(e);
    };

    //初始化id
    _.flakeId = new SnowFlakeId();
  },
  created() {
    _ = this;
    _.queryClazzProduct(null);
  },
  methods: {
    //调整选择的元素透明度
    changeSelectElsOpacity() {
      var activeObj = _.fabricObj.getActiveObjects();
      for (var i = 0; i < activeObj.length; i++) {
        console.log("更改透明度");
        activeObj[i]["opacity"] = _.userDefined.opacity;
      }
      _.fabricObj.renderAll();
    },

    //是否展示商品
    showClassGoods(tp, index) {
      if (tp == "subClass") {
        if (_.currentOpenIndex == index) {
          _.currentOpenIndex = 0;
        } else {
          _.currentOpenIndex = index;
        }
      } else if (tp == "parentClass") {
        if (_.parentOpenIndex == index) {
          _.parentOpenIndex = -1;
        } else {
          _.parentOpenIndex = index;
          _.currentOpenIndex = 0;
        }
      }
    },
    //展示容器大小
    showContainerSizeInfo() {
      var arrObjs = _.fabricObj.getObjects();
      if (arrObjs.length > 1) {
        for (var i = 0; i < arrObjs.length; i++) {
          //移除元素
          if (arrObjs[i]["calcObj"] && arrObjs[i]["calcObj"] == 3) {
            _.fabricObj.remove(arrObjs[i]);
          }
        }
      }
      var text =
        "容器大小 宽：" +
        (_.userDefined.width ?? 0) +
        " 高：" +
        (_.userDefined.height ?? 0) +
        " 深度：" +
        (_.userDefined.deep ?? 0) +
        "   ｜   客户名称：" +
        (_.userDefined.customerName ?? "-") +
        "   ｜   备注：" +
        (_.userDefined.remark ?? "");
      var sizeInfo = new fabric.Text(text, {
        left: 0, //Take the block's position
        top: 720,
        fill: "black",
        fontSize: 13,
        selectable: false,
        erasable: false,
        calcObj: 3,
      });
      console.log(text);
      console.log(sizeInfo);
      _.fabricObj.add(sizeInfo);
    },

    /**绘制背景 */
    drawColorBackground() {
      var colorback = new fabric.Rect({
        left: 0,
        top: 0,
        fill: "#C3C3C3",
        width: 1160,
        height: 740,
        selectable: false,
        erasable: false,
        elType: 0,
      });
      _.fabricObj.add(colorback);
    },

    //请求分类数据
    queryClazzProduct(name) {
      console.log(axios);
      axios
        .get("https://scene.tianfuss.com/nk/sc-product/queryV2", {
          params: { name: name },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            _.goods = res.data.object;
            _.orignGoods = res.data.object;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //数量修改
    numChangeCalcHandler(opt) {
      console.log(undefined != opt.target["text"]);
      if (opt.target["text"]) {
        var numText = opt.target.text + "";
        var result = numText.replace(/\s*/g, "").replace("x", "");
        for (var i = 0; i < _.selectRightEls.length; i++) {
          if (_.selectRightEls[i]["id"] == opt.target["elInfo"]["id"]) {
            _.selectRightEls[i]["num"] = parseFloat(result);
          }
        }
        _.drawPriceLine();
      }
    },

    //橡皮擦处理
    eraseHandler(bol) {
      _.erase = bol;
      if (bol == "eraseactive") {
        _.fabricObj.isDrawingMode = true; // 进入绘画模式
        _.fabricObj.freeDrawingBrush = new fabric.EraserBrush(_.fabricObj); // 使用橡皮擦画笔
        _.fabricObj.freeDrawingBrush.width = 10;
      } else if (bol == "erasenormal") {
        _.fabricObj.isDrawingMode = false; // 进入绘画模式
      } else if (bol == "recoveractive") {
        //恢复
        _.fabricObj.isDrawingMode = true;
        _.fabricObj.freeDrawingBrush.inverted = true;
      } else if (bol == "recovernormal") {
        _.fabricObj.isDrawingMode = false; // 进入绘画模式
      }
    },
    //橡皮擦处理
    backgroundImgFixHandler(bol) {
      _.backImgFix = bol;
      if (bol) {
        var el = _.fabricObj.getActiveObjects();
        for (var i = 0; i < el.length; i++) {
          if (el[i]["isContainer"] == 1) {
            _.fabricObj.remove(el[i]);
            el[i]["selectable"] = false;
            _.fabricObj.add(el[i]);
          }
        }
      } else {
        _.backImgComponent["selectable"] = true;
      }
    },
    //右键菜单项操作
    rightMenuHandler(tp) {
      var activeObj = _.fabricObj.getActiveObjects();
      for (var i = 0; i < activeObj.length; i++) {
        console.log("移动层级对象", activeObj[i]);
        if (tp == "top") {
          //置顶
          // activeObj[i].bringToFront();
          _.fabricObj.bringToFront(activeObj[i]);
        } else if (tp == "bottom") {
          //置底
          // activeObj[i].sendToBack();
          _.fabricObj.sendToBack(activeObj[i]);
          _.makeColorBackgroundToBottom();
        } else if (tp == "up") {
          //上一层
          if (activeObj[i]["zIndex"]) {
            var zIndex = activeObj[i]["zIndex"];
            activeObj[i].moveTo(zIndex + 10);
            activeObj[i]["zIndex"] = zIndex + 10;
          } else {
            var index = _.queryElLocation(activeObj[i]);
            activeObj[i].moveTo(index + 10);
            activeObj[i]["zIndex"] = index + 10;
          }

        } else if (tp == "down") {
          //下一层

          //获取元素当前所处层级
          if (activeObj[i]["zIndex"]) {
            var zIndex = activeObj[i]["zIndex"];
            if (zIndex >= 14) {
              activeObj[i].moveTo(zIndex - 10);
              activeObj[i]["zIndex"] = zIndex - 10;
            } else {
              activeObj[i].moveTo(3);
              activeObj[i]["zIndex"] = 3;
              _.makeColorBackgroundToBottom();
            }
          } else {
            var index = _.queryElLocation(activeObj[i]);
            console.log("此时下标", index);
            if (index < 14) {
              activeObj[i].moveTo(3);
              activeObj[i]["zIndex"] = 3;
              _.makeColorBackgroundToBottom();
            } else {
              activeObj[i].moveTo(index - 10);
              activeObj[i]["zIndex"] = index - 10;
            }
          }
        } else if (tp == "delete") {
          //删除
          _.monitorEventKey({ keyCode: 68 });
        }
      }
    },
    //将背景置于最底部
    makeColorBackgroundToBottom() {
      var bk = _.fabricObj.getObjects();
      for (var i = 0; i < bk.length; i++) {
        if (bk[i]["elType"] != undefined && bk[i]["elType"] == 0) {
          console.log("将背景质地", bk[i]);
          _.fabricObj.sendToBack(bk[i]);
        }
      }
    },
    //获取元素所处的层级位置
    queryElLocation(el) {
      var bk = _.fabricObj.getObjects();
      var index = 0;
      for (var i = 0; i < bk.length; i++) {
        if (bk[i]["elInfo"] && bk[i]["elInfo"]["id"] == el["elInfo"]["id"]) {
          index = i + 1;
        }
      }
      return index;
    },

    //鼠标按键事件
    mouseKeyClick(e, direction) {
      //鼠标右键
      if (direction == "r") {
        var activeObj = _.fabricObj.getActiveObjects();
        //必须要有选中元素
        if (activeObj.length > 0) {
          let pointX = e.pageX + 2;
          let pointY = e.pageY + 2;
          _.rightMenuInfo.visible = true;
          _.rightMenuInfo.top = pointY;
          _.rightMenuInfo.left = pointX;
        }
      } else {
        _.rightMenuInfo.visible = false;
      }
    },

    //对象输出图
    exportCanvasToImg() {
      // _.fabricObj.contextContainer.canvas.getContext('2d').setTransform(0.2, 0, 0, 0.2, 0, 0)
      // _.fabricObj.getContext('2d').setTransform((0.2, 0, 0, 0.2, 0, 0))
      var img_png_src = _.fabricObj.toDataURL("image/jpeg",1.0);
      var save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = img_png_src;
      save_link.download = "./造景图.jpg";
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      save_link.dispatchEvent(event);
    },

    //导入背景图片
    importCanvasToImg() {
      console.log(_.$refs);
      _.$refs.uploadFile.click();
    },
    //上传背景文件处理
    importFileHandler(file) {
      var selectFile = file.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(selectFile);
      reader.onload = (res) => {
        let base64 = res.target.result;
        _.drawBackground(base64);
      };
    },
    //绘制背景
    drawBackground(base64) {
      //删除背景
      var bk = _.fabricObj.getObjects();
      for (var i = 0; i < bk.length; i++) {
        if (bk[i]["isBackImage"] && bk[i]["isBackImage"] == 1) {
          _.fabricObj.remove(bk[i]);
        }
      }
      fabric.Image.fromURL(base64, (imgInstance) => {
        console.log("图盘", imgInstance);
        // imgInstance['selectable']=false;
        console.log("宽度", imgInstance.width, "高度", imgInstance.height);
        console.log(
          "scaleX",
          890 / imgInstance.width,
          "scaleY",
          156 / imgInstance.height
        );
        imgInstance["erasable"] = false;
        imgInstance["scaleX"] = 890 / imgInstance.width;
        imgInstance["scaleY"] = 890 / imgInstance.width;
        imgInstance["isContainer"] = 1;
        imgInstance["isBackImage"] = 1;
        _.fabricObj.add(imgInstance);
        imgInstance.moveTo(4);
        _.backImgComponent = imgInstance;
      });
    },

    //图片事件监听
    monitorEventKey(e) {
      var activeObj = _.fabricObj.getActiveObjects();
      if (e.keyCode == 68) {
        //删除按键
        if (activeObj != null && activeObj.length > 0) {
          for (var i = 0; i < activeObj.length; i++) {
            _.fabricObj.remove(activeObj[i]);
            if (activeObj[i]["single"]) {
              return;
            }
            //删除选择栏目
            for (var j = 0; j < _.selectionEls.length; j++) {
              if (
                activeObj[i]["elInfo"]["uuid"] &&
                activeObj[i]["elInfo"]["uuid"] == _.selectionEls[j]["uuid"]
              ) {
                _.selectionEls.splice(j, 1);
                console.log("删除了1", activeObj[i]["elInfo"]["uuid"]);
                _.removeToEls(activeObj[i]["elInfo"]["uuid"]);
              }
            }
            for (var j = 0; j < _.selectRightEls.length; j++) {
              if (
                activeObj[i]["elInfo"]["uuid"] &&
                activeObj[i]["elInfo"]["uuid"] == _.selectRightEls[j]["uuid"]
              ) {
                _.selectRightEls.splice(j, 1);
                console.log(activeObj[i]["elInfo"]);
                console.log("删除了2", activeObj[i]["elInfo"]["uuid"]);
                _.removeToEls(activeObj[i]["elInfo"]["uuid"]);
              }
            }
          }
        }
      } else if (e.keyCode == 86 && e.ctrlKey) {
        //复制按键 ctrl+v
        if (activeObj != null && activeObj.length > 0) {
          for (var i = 0; i < activeObj.length; i++) {
            var els = activeObj[i];
            const image = new Image();
            image.crossOrigin = "anonymous";
            image.src = els.elInfo["url"];
            image.onload = function () {
              const imgInstance = new fabric.Image(
                image,
                _.$SHAPECONFIG.imgStyle
              );
              imgInstance.top = els.top + 5;
              imgInstance.left = els.left + 5;
              imgInstance["elInfo"] = els.elInfo;
              _.fabricObj.add(imgInstance);
            };
          }
        }
      }
    },

    //选择了图片
    dragImgDown(e) {
      console.log("按下了");
      _.isDrag = true;
    },
    /**
     * 拖拽图片释放鼠标时的操作
     * @param {*} e
     */
    dragImgOut(e, item) {
      if (_.isDrag) {
        if (item["code"] == "0") {
          console.log("添加的是设备");
          //如果是设备，则只添加文字不添加图片
          item["isCalc"] = 0;
          item["num"] = 1;
          item["uuid"] = _.flakeId.generate();
          _.addRightEls(item, 1);
          _.isDrag = false;
        } else {
          console.log("释放", e);
          _.isDrag = false;
          item["isCalc"] = 1;
          item["uuid"] = _.flakeId.generate();
          const image = new Image();
          image.setAttribute("crossorigin", "anonymous");
          image.src = item.img + "?tamp=" + new Date().valueOf();
          console.log("图片", image);
          image.onload = function () {
            //图片加载完，再draw 和 toDataURL
            const imgInstance = new fabric.Image(
              image,
              _.$SHAPECONFIG.imgStyle
            );
            imgInstance.top = _.mouseMoveXY.y - image.height / 40;
            imgInstance.left = _.mouseMoveXY.x - image.width / 40;
            imgInstance["elInfo"] = item;
            
            //数量是1 默认
            item["num"] = 1;

            _.addTopEls(item, 1);
            var rs = _.addRightEls(item, 1);
            imgInstance["single"] = !rs;
            imgInstance['mainEls']=true;
            _.fabricObj.add(imgInstance);

            //添加图层
            _.picLevel.push(item);
          };
        }
      }
    },


    //图层拖拽
    dragLevelDown(e,index){
      _.isDrag = true;
      _.currentLevel=index;
      console.log("调整的是第几个层级",index)
    },

    //层级拖拽释放
    dragLevelOut(e,item){
      if (_.isDrag) {
        var enterItem= _.enterLevelItem;
        var picDatas=_.picLevel;
        console.log("修改之前",item,_.picLevel)

        var ispre=true;
        for(let i=0;i<picDatas.length;i++){
          if(enterItem.uuid==picDatas[i]['uuid']){
            console.log("index=>>",i)
            if(i>_.currentLevel){
              ispre=false;
            }
          }
        }

        //删除元素
        for(let i=0;i<picDatas.length;i++){
          if(item.uuid==picDatas[i]['uuid']){
            console.log("index=>>",i)
            picDatas.splice(i,1);
            break;
          }
        }

        //追加元素
        for(let i=0;i<picDatas.length;i++){
          if(enterItem.uuid==picDatas[i]['uuid']){
            console.log("index=>>",i)
            if(ispre){
              picDatas.splice(i,0,item);
            }else{
              picDatas.push(item);
            }
            break;
          }
        }
        _.isDrag=false;
        _.reloadWindowEls();
      }
    },
    //拖拽进入元素触发
    dragLevelEnter(e,item){
      if (_.isDrag&&_.picLevel[_.currentLevel].uuid!=item.uuid) {
        console.log("进入哪个元素",item)
        _.enterLevelItem=item;
      }
    },
    //顶部增加元素 handleType 1代表，需要去重复
    addTopEls(item, handleType) {
      var x = 0;
      var y = 0;
      for (var i = 0; i < _.selectionEls.length; i++) {
        if (_.selectionEls[i]["isCalc"] == 1) {
          x += 68;
        }
      }

      //判断元素是否已存在
      if (handleType && handleType == 1) {
        var arrObjs = _.fabricObj.getObjects();
        if (arrObjs.length > 1) {
          var repeatNum = 0;
          for (var i = 0; i < arrObjs.length; i++) {
            if (arrObjs[i]["elInfo"] && arrObjs[i]["elInfo"]["id"] == item.id) {
              repeatNum++;
            }
          }
          if (repeatNum > 1) {
            return;
          }
        }
      }
      _.selectionEls.push(item);
      if (_.selectionEls.length > 13) {
        y = 86;
        x = 0;
        for (var i = 1; i < _.selectionEls.length - 13; i++) {
          x += 68;
        }
      }
      _.$SHAPE.drawSmallImgAndText(item, _.fabricObj, x, y);
    },

    //顶部增加元素
    addRightEls(item, handleType) {
      var y = 0;

      //判断元素是否已存在
      if (handleType && handleType == 1) {
        var arrObjs = _.fabricObj.getObjects();
        if (arrObjs.length > 1) {
          var repeatNum = 0;
          for (var i = 0; i < arrObjs.length; i++) {
            //移除元素
            if (arrObjs[i]["elInfo"] && arrObjs[i]["elInfo"]["id"] == item.id) {
              repeatNum++;
            }
          }
          if (repeatNum > 1) {
            return false;
          }
        }
      }

      _.selectRightEls.push(item);
      for (var i = 1; i < _.selectRightEls.length; i++) {
        y += 20;
      }
      _.$SHAPE.drawSmallImgAndTextAndPrice(item, _.fabricObj, 900, y);
      _.drawPriceLine();
      return true;
    },
    //计算总金额线
    drawPriceLine() {
      _.deleteComponent(2);
      var y = 0;
      var totalPrice = 0;
      for (var i = 0; i < _.selectRightEls.length; i++) {
        y += 20;
        totalPrice +=
          parseFloat(_.selectRightEls[i]["price"]) *
          (_.selectRightEls[i]["num"] ?? 1);
      }
      _.$SHAPE.drawPriceLine(_.fabricObj, 900, y, totalPrice);
    },

    //2 代表总金额线条
    deleteComponent(tp) {
      var objs = _.fabricObj.getObjects();
      for (var i = 0; i < objs.length; i++) {
        if (objs[i]["tagEl"] && objs[i]["tagEl"] == 2) {
          _.fabricObj.remove(objs[i]);
        }
      }
    },


    /**
     * 重新绘制窗口元素
     */
    reloadWindowEls(){
      var objs = _.fabricObj.getObjects();
      console.log("所有元素",objs)
      //排序思路就是重新绘制
      //先便利调整过顺序的元素，来进行匹配
      //依次重新绘制
      var newLevelObjs=[];
      for(var t=0;t<_.picLevel.length;t++){
          //用一个容器来装
          console.log(_.picLevel[t]['uuid'])
        for (var i = 0; i < objs.length; i++) {
            if(objs[i]['elInfo']&&
              objs[i]['elInfo']['uuid']==_.picLevel[t]['uuid']&&
              objs[i]['mainEls']){
              newLevelObjs.push(objs[i]);
            }
        }
      }

      //删除这些元素，再重新添加这些元素
      for(var i=newLevelObjs.length-1;i>=0;i--){
        _.fabricObj.remove(newLevelObjs[i]);
        _.fabricObj.add(newLevelObjs[i]);
      }
      
    },

    //重新绘制上边和右侧的元素
    reloadTopAndRightEls() {
      var objs = _.fabricObj.getObjects();
      var topItems = [];
      var rightItems = [];
      // //重置元素
      _.selectionEls = [];
      _.selectRightEls = [];
      for (var i = 0; i < objs.length; i++) {
        if (objs[i]["position"] == "t") {
          if (objs[i]["calcObj"] && objs[i]["calcObj"] == 1) {
            topItems.push(objs[i]["elInfo"]);
          }
          _.fabricObj.remove(objs[i]);
        }
        if (objs[i]["position"] == "r") {
          if (objs[i]["calcObj"] && objs[i]["calcObj"] == 2) {
            rightItems.push(objs[i]["elInfo"]);
          }
          _.fabricObj.remove(objs[i]);
        }
      }
      console.log("右侧：", _.selectRightEls);
      console.log("左侧", _.selectionEls);
      console.log("右侧2：", topItems);
      console.log("左侧2", rightItems);
      //重新绘制
      topItems.forEach((item) => {
        console.log("绘制", item);
        _.addTopEls(item);
      });
      rightItems.forEach((item) => {
        _.addRightEls(item);
      });
      // _.drawPriceLine();
    },

    //顶部移除元素
    removeToEls(uuid) {
      var arrObjs = _.fabricObj.getObjects();
      for (var i = 0; i < arrObjs.length; i++) {
        //移除元素
        if (arrObjs[i]["elInfo"] && arrObjs[i]["elInfo"]["uuid"] == uuid) {
          _.fabricObj.remove(arrObjs[i]);
          console.log("删除", arrObjs[i]);
        }
      }
      //重新绘制图像
      _.reloadTopAndRightEls();
    },

    //清除所有元素
    removeAllEls() {
      var arrObjs = _.fabricObj.getObjects();
      for (var i = 0; i < arrObjs.length; i++) {
        //移除元素
          _.fabricObj.remove(arrObjs[i]);
      }
    },

    //重新编排所有元素
    removeAllEls(items) {
      var arrObjs = _.fabricObj.getObjects();
      for(var t=0;t<items.length;t++){
        for (var i = 0; i < arrObjs.length; i++) {
          //移除元素
            _.fabricObj.remove(arrObjs[i]);
        }
      }
    },

    /**找到相同的元素 */
    foundSameEls(arr, uuid) {
      var newArr = [];
      for (var i = 0; i < arr.length; i++) {
        //移除元素
        if (arr[i]["uuid"] != uuid) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    /**
     * 绘制形状
     * @param {*} params
     */
    drawShape(params) {
      //清除容器内容
      _.fabricObj.clear();
      //设置画布背景颜色
      _.fabricObj.setBackgroundColor("#4a7983");
      //重新绘制立方体图形
      _.$SHAPE.drawCubeV2(
        (params.left ??= 50),
        (params.top ??= 50),
        params.width,
        params.height,
        params.deep,
        _.fabricObj
      );
    },
  },
};
</script>
  
  <style lang="less" scoped>
.designContainer {
  display: flex;
  align-items: flex-start;
  &_left_setting {
    width: 230px;
    height: 700px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &_title {
      font-size: 18px;
      font-family: "苹方";
      color: #bbb;
      margin-top: 0px;
    }

    /**搜索框 */
    &_search {
      margin-bottom: 10px;
      margin-top: 10px;
      input {
        border: 1px solid #eee;
        height: 36px;
        width: 220px;
        border-radius: 8px;
        margin-left: 4px;
      }
    }

    /**搜索物品列表 */
    &_list {
      overflow: auto;
      &_content {
        display: flex;
        flex-direction: column;
        &_class {
          background-color: rgba(71, 117, 203, 0.882);
          color: white;
          border-radius: 10px;
          padding-left: 10px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 10px;
        }
        &_subclass {
          background-color: rgba(71, 117, 203, 0.882);
          color: white;
          border-radius: 10px;
          padding-left: 10px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 10px;
          margin-top: 10px;
          margin-left: 20px;
          margin-bottom: 10px;
        }
        &_product {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          &_item {
            scrollbar-width: none;
            background: rgb(240, 240, 240);
            border-radius: 12px;
            box-shadow: 0px 0px 15px 0px rgba(171, 169, 169, 0.4);
            text-align: center;
            font-size: 14px;
            height: 80px;
            width: 80px;
            margin: 0 auto;
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              // width: 100px;
              height: 34px;
            }
          }
        }
      }
    }
  }

  /**右侧展示canvas */
  &_right_show {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_elements {
      display: flex;
      justify-content: flex-start;
      width: 1000px;
      height: 80px;

      &_item {
        &_title {
          font-size: 12px;
        }
      }
    }
    /**操作图标 */
    &_operation {
      display: flex;
      justify-content: space-between;
      width: 1000px;
      &_erase {
        width: 30px;
        height: 30px;
      }
    }

    /**图层 */
    &_level {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      img {
        width: 50px;
      }
    }

    &_define {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;

      &_item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        input {
          border: 1px solid #eee;
          height: 24px;
          border-radius: 4px;
          margin-left: 4px;
          width: 80px;
        }
      }
    }

    /**底部图片保存按钮 */
    &_btns {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
      width: 600px;
      &_next {
        width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        color: white;
        background: rgba(71, 117, 203, 0.882);
      }
      &_import {
        width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        color: white;
        background: rgba(15, 190, 85, 0.882);
      }
    }
  }

  /**右键菜单 */
  &_right_menu {
    background: white;
    position: absolute;
    border: 1px solid #eee;
    border-bottom: 0px;
    &_item:nth-last-child(n + 1) {
      background: white;
      height: 30px;
      font-size: 14px;
      width: 100px;
      line-height: 30px;
      text-align: center;
      border-bottom: 1px solid #eee;
    }
    &_item:hover {
      background: rgba(71, 117, 203, 0.882);
      color: white;
      cursor: pointer;
    }
  }
}
</style>

  