import { createApp } from 'vue'
import App from './App.vue'
import SHAPECONFIG from './config/shapeConfig.js'
import SHAPE from './utils/shapeToolUtils.js'
import COMMON from './utils/common.js'
// import axios from 'axios'

const app=createApp(App);
app.config.globalProperties.$SHAPECONFIG= SHAPECONFIG;
app.config.globalProperties.$SHAPE= SHAPE;
app.config.globalProperties.$COMMON= COMMON;
// app.config.globalProperties.$AXIOS= axios;
app.mount('#app')
