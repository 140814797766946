//各个形状初始化配置
const lineStyle={
    stroke: '#9cd2e5',//笔触颜色
    strokeWidth: 1,//笔触宽度
    hasControls: false, //选中时是否可以放大缩小
    hasRotatingPoint: false,//选中时是否可以旋转
    hasBorders:false,//选中时是否有边框
    transparentCorners:true,
    perPixelTargetFind:true,//默认false。当设置为true，对象的检测会以像互点为基础，而不是以边界的盒模型为基础。
    lockMovementX: true,//X轴是否可被移动(true为不可，因为前缀是lock)
    lockMovementY: true,//Y轴是否可被移动(true为不可，因为前缀是lock)
    centeredScaling:true,
    centeredRotation:true,
    selectable:false,
    erasable: false
}
//线条颜色V2,此样式带有笔触宽度和颜色
const lineStyleV2={
    stroke: '#b3e8fa',//笔触颜色
    strokeWidth: 4,//笔触宽度
    hasControls: false, //选中时是否可以放大缩小
    hasRotatingPoint: false,//选中时是否可以旋转
    hasBorders:false,//选中时是否有边框
    transparentCorners:true,
    perPixelTargetFind:true,//默认false。当设置为true，对象的检测会以像互点为基础，而不是以边界的盒模型为基础。
    lockMovementX: true,//X轴是否可被移动(true为不可，因为前缀是lock)
    lockMovementY: true,//Y轴是否可被移动(true为不可，因为前缀是lock)
    centeredScaling:true,
    centeredRotation:true,
    selectable:false,
    erasable: false
}

//图片样式
const imgStyle={
    left: 100, // 图片相对画布的左侧距离
    top: 100, // 图片相对画布的顶部距离
    opacity: 1, // 图片透明度
    scaleX:0.2,
    scaleY:0.2,
    // 这里可以通过scaleX和scaleY来设置图片绘制后的大小，这里为原来大小的一半
    transparentCorners: true,
    cornerColor: '#fff',
    cornerStrokeColor: '#fff',
    borderColor: '#eee',
    cornerSize: 12,
    padding: 10,
    cornerStyle: 'circle',
    borderDashArray: [3, 3],
    selectable:true
}





const config={
    lineStyle,
    lineStyleV2,
    imgStyle
}

export default config;